import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-help-info',
  templateUrl: './help-info.page.html',
  styleUrls: ['./help-info.page.scss'],
})
export class HelpInfoPage implements OnInit {
  @Input() description = '';
  @Input() url = '';

  constructor() { }

  ngOnInit() {
  }

}
