import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ColorGeneratorPage } from './color-generator/color-generator.page';
import { SellerImageConfigPage } from './components/seller-image-config/seller-image-config.page';
import { HelpInfoPage } from './help-info/help-info.page';
@NgModule({
    imports: [
      CommonModule
    ],
    declarations: [ColorGeneratorPage,SellerImageConfigPage, HelpInfoPage],
    exports: [ColorGeneratorPage, SellerImageConfigPage, HelpInfoPage]
  })
  export class HigherCommonModule {}