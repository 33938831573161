import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import { LoginService } from './login.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BrandService {  
  private url: string = environment.admin;  
  constructor(private http:HttpClient,private login:LoginService) {    
  }
  async list() {    

    return this.http.get<any[]>(`${this.url}/brand/` , {
      headers: new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization' , this.login.getToken())      
    });
  }

  async listAll() {    

    return this.http.get<any[]>(`${this.url}/brands/` , {
      headers: new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization' , this.login.getToken())      
    });
  }
  getOne(id){
    return this.http.get<any[]>(`${this.url}/brand/${id}` , {
      headers: new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization' , this.login.getToken())      
    });
  }
  update(id,brand){
    delete brand.__v;
    return this.http.patch<any[]>(`${this.url}/brand/${id}` , brand,{
      headers: new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization' , this.login.getToken())      
    });
  }
  insert(brand){
    brand.status = true;
    return this.http.post<any[]>(`${this.url}/brand/` ,brand, {
      headers: new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization' , this.login.getToken())      
    });
  }

}