import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import { LoginService } from './login.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ProductService {
  private url: string = environment.admin;
  constructor(private http: HttpClient, private login: LoginService) {
  }
  async list() {

    return this.http.get<any[]>(`${this.url}/product/`, {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
        .set('Authorization', this.login.getToken())
    });
  }

  async listBySeller() {
    return this.http.get<any[]>(`${this.url}/product/listBySeller`, {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
        .set('Authorization', this.login.getToken())
        .set('sellerId', this.login.getSellerId())
    });
  }

  async listBySellerFormatted() {
    return this.http.get<any[]>(`${this.url}/product/listBySellerFormatted`, {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
        .set('Authorization', this.login.getToken())
        .set('sellerId', this.login.getSellerId())
    });
  }

  getOne(id) {
    return this.http.get<any[]>(`${this.url}/product/${id}`, {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
        .set('Authorization', this.login.getToken())
    });
  }
  update(id, product) {
    console.log('update product ',id,product)
    delete product.__v;
    return this.http.patch<any[]>(`${this.url}/product/${id}`, product, {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
        .set('Authorization', this.login.getToken())
    });
  }
  insert(product) {
    product.status = true;
    return this.http.post<any[]>(`${this.url}/product/`, product, {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
        .set('Authorization', this.login.getToken())
    });
  }

  // stock methods

  insertProductStock(id, product, stock, stockElement) {
    const formData = { product, stock, stockElement };
    return this.http.post<any[]>(`${this.url}/product/insertStock/${id}`, formData, {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
        .set('Authorization', this.login.getToken())
    });
  }
  
  updateProductStock(id, stock, prevStockElement, stockElement) {
    const formData = { stock, prevStockElement, stockElement };
    return this.http.patch<any[]>(`${this.url}/product/updateStock/${id}`, formData, {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
        .set('Authorization', this.login.getToken())
    });
  }

  deleteProductStock(id, stock, stockElement) {
    const formData = { stock, stockElement };
    return this.http.post<any[]>(`${this.url}/product/deleteStock/${id}`, formData, {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
        .set('Authorization', this.login.getToken())
    });
  }
}
