import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})

export class UserService {
  private url = environment.admin;

  constructor(private http: HttpClient) {
  }

  // add information to the seller too

  createUserByToken(user, token, seller){
    const userForm = { user, token , seller};
    return this.http.post<any[]>(`${this.url}/user/byToken/`,userForm,{
      headers: new HttpHeaders()
      .set('Content-Type', 'application/json')
    });
  }

  getUserById(id)  {
    return this.http.get<any[]>(`${this.url}/user/${id}`,{
      headers: new HttpHeaders()
      .set('Content-Type', 'application/json'),
    });
  }

  updateById(id,user){
    console.log('user:',user,'id:',id)
    delete user.__v;
    if(user.token){
      delete user.token;
    }
    if(user.password){
      delete user.password;
    }

    return this.http.patch<any[]>(`${this.url}/user/${id}` , user,{
      headers: new HttpHeaders()
      .set('Content-Type', 'application/json')
      //.set('Authorization' , this.login.getToken())      
    });
  }

  updatePassword(id,user,newPassword){
    delete user.__v;
    var user = user;
    
    user.password = newPassword

    if(user.token){
      delete user.token;
    }
    
    return this.http.patch<any[]>(`${this.url}/user/${id}` , user,{
      headers: new HttpHeaders()
      .set('Content-Type', 'application/json')
      //.set('Authorization' , this.login.getToken())      
    });
  }

  requestInstructions(email){
    const data = {email};
    return this.http.post<any[]>(`${this.url}/user/passwordRecovery`,data, {
      headers: new HttpHeaders()
      .set('Content-Type', 'application/json')
    });
  }

  checkIfUserExist(token){
    const data = {token};
    return this.http.post<any[]>(`${this.url}/user/checkIfUserExistByToken`,data, {
      headers: new HttpHeaders()
      .set('Content-Type', 'application/json')
    });
  }

  updateUserByToken(token, newPassword){
    const data = {token, newPassword};

    return this.http.post<any[]>(`${this.url}/user/newPasswordByToken`,data, {
      headers: new HttpHeaders()
      .set('Content-Type', 'application/json')
    });
  }
}
