import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import { LoginService } from './login.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CategoryService {  
  private url: string = environment.admin;    
  constructor(private http:HttpClient,private login:LoginService) {    
  }
  async listAll() {    

    return this.http.get<any[]>(`${this.url}/categories/` , {
      headers: new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization' , this.login.getToken())      
    });
  }

  async list() {    
    return this.http.get<any[]>(`${this.url}/category/` , {
      headers: new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization' , this.login.getToken())      
    });
  }
  getOne(id){
    return this.http.get<any[]>(`${this.url}/category/${id}` , {
      headers: new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization' , this.login.getToken())      
    });
  }
  update(id,category){
    delete category.__v;
    return this.http.patch<any[]>(`${this.url}/category/${id}` , category,{
      headers: new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization' , this.login.getToken())      
    });
  }
  insert(category){
    category.status = true;
    return this.http.post<any[]>(`${this.url}/category/` ,category, {
      headers: new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization' , this.login.getToken())      
    });
  }

  getOneByAlias(alias){
    return this.http.get<any[]>(`${this.url}/category/getByAlias/` , {
      headers: new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('categoryalias', alias)
    });
  }
}
